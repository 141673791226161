import React from "react";
import PropTypes from "prop-types";
import { GameTitleContainer, GameLogo } from "./GameTitle.styled";

const GameTitle = (props) => {
  console.log("Current graphics object:", props.graphics);

  const isValidUrl = (text) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // podržava http ili https
        "((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|" + // domen
        "localhost|" + // lokalno hostovanje
        "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})" + // IP adresa
        "(\\:\\d+)?(\\/.*)?$", // port i putanja
      "i"
    );
    return urlPattern.test(text);
  };

  const getDomainFromUrl = (url) => {
    return new URL(url).hostname.replace("www.", "");
  };

  const areGraphicsKeysEmpty = (graphics) => {
    if (!graphics) return true; // Ako nema graphics, tretiramo kao prazno
    const keysToCheck = [
      "masterHead",
      "primaryLogoUrl",
      "secondaryLogoUrl",
      "primaryText",
    ];
    return keysToCheck.every((key) => !graphics[key]); // Proverava da li su svi ključevi prazni ili `undefined`
  };

  if (areGraphicsKeysEmpty(props.graphics)) {
    console.log(
      "OVDE SAM! Grafika je prazna za header. Dodali smo veliku sliku, SAMO JO PROMENI WIDTH NA 370px I ZA MOBILE I ZA WEB"
    );
    return (
      <GameTitleContainer>
        <GameLogo src="/newBigLogo1.png" alt="new big logo" />
      </GameTitleContainer>
    );
  }

  // Standardni prikaz kada graphics postoji
  return (
    <GameTitleContainer>
      {props.graphics.primaryLogoUrl ? (
        <div
          className="primary-logo-container"
          dangerouslySetInnerHTML={{ __html: props.graphics.primaryLogoUrl }}
        ></div>
      ) : (
        <GameLogo src="/logo.jpg" alt="logo" />
      )}
    </GameTitleContainer>
  );
};

GameTitle.propTypes = {
  graphics: PropTypes.object,
};

export default GameTitle;
