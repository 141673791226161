import React from "react";
import PropTypes from "prop-types";
import "./Sponsor.scss";

const Sponsor = ({ graphics }) => {
  // Provera da li je footer prazan
  const isFooterEmpty = !graphics?.footerText && !graphics?.footerLogoUrl;

  if (isFooterEmpty) {
    // Default footer prikaz
    return (
      <div className="sponsor-wrapper" style={{ marginBottom: "20px" }}>
        <a
          href="https://extras.crypticdj.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/defaultDjFooter1.gif" // Putanja do default slike
            alt="More from Cryptic DJ"
            style={{ maxWidth: "300px", height: "auto" }} // Responsive stil
          />
        </a>
      </div>
    );
  }

  if (graphics?.footerLogoUrl) {
    // Custom footer sa HTML-om
    return (
      <div
        className="sponsor-wrapper"
        style={{ marginBottom: "20px" }}
        dangerouslySetInnerHTML={{ __html: graphics.footerLogoUrl }}
      ></div>
    );
  }

  // Defaultni fallback za svaki slučaj
  return (
    <div className="sponsor-wrapper" style={{ marginBottom: "20px" }}>
      <a
        href="https://extras.crypticdj.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/defaultDjFooter1.gif" // Putanja do default slike
          alt="More from Cryptic DJ"
          style={{ maxWidth: "300px", height: "auto" }} // Responsive stil
        />
      </a>
    </div>
  );
};

Sponsor.propTypes = {
  graphics: PropTypes.object,
};

export default Sponsor;
