import { Box } from "@mui/material";
import styled from "styled-components";

export const GameTitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Calibri, sans-serif;
  color: white;
  margin-bottom: 20px;
`;

export const GameLogo = styled("img")`
  width: 300px; /* Fiksna širina */
  height: auto; /* Automatsko prilagođavanje visine */
`;
